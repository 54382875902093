<div></div>
<div class="kurz-price-container">
  <div class="kurz-price-display-label-container">

    <div class="kurz-price-display-label">{{ label || ('priceDisplay.label' | cxTranslate) }}</div>

    <app-icon
      *ngIf="showIcon"
      tabindex="0"
      role="button"
      aria-label="info"
      class="kurz-price-display-info-icon"
      iconType="INFO"
      iconSize="SMALL"
      (activateElement)="infoActivated($event)"
    ></app-icon>

  </div>

  <div class="kurz-price-display-separator">
    <div class="kurz-price-display-separator-line"></div>
  </div>

  <div class="kurz-price-display-value">
    <!-- <div>{{ formattedPrice$ | async }}</div> -->

    <app-kurz-differently-styled-value
      [valueObjects]="(formattedPrice$ | async) | differentlyStyledFirstNumber : [null, 1, {fontWeight: 'bold'}] : [null, 0.8] "
    ></app-kurz-differently-styled-value>

  </div>
</div>
