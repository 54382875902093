import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { KurzUnitFormattedPriceOptions, KurzUnitService } from 'src/app/custom/services/kurz-unit.service';


export interface KurzPriceDisplayOptions extends KurzUnitFormattedPriceOptions {
  value: number;
}


@Component({
  selector: 'app-kurz-price-display',
  templateUrl: './kurz-price-display.component.html',
  styleUrls: ['./kurz-price-display.component.scss']
})
export class KurzPriceDisplayComponent implements OnInit, OnDestroy {

  private resizeObserver = new ResizeObserver(() => {
    this.calculateSeparationLineLength();
  });

  private formattedPriceBehaviorSubject = new BehaviorSubject<string>('-');

  get formattedPrice$(): Observable<string> {
    return this.formattedPriceBehaviorSubject.asObservable();
  }

  get showIcon(): boolean {
    return !!this.info?.observers?.length;
  }

  @Input()
  set options(obj: KurzPriceDisplayOptions) {
    const str = this.kurzUnitService?.getFormattedPrice?.(obj?.value, obj);
    this.formattedPriceBehaviorSubject.next(str || '-');
  }

  @Input()
  label: string;

  @Input()
  set size(value: string) {
    const prop = '--kurz-price-display-font-size';
    switch (value) {
      case 'EXTRA_SMALL': this.utilCustomCSSPropertyService.setValue(prop, '12px', this.elementRef?.nativeElement); break;
      case 'SMALL': this.utilCustomCSSPropertyService.setValue(prop, '14px', this.elementRef?.nativeElement); break;
      case 'MEDIUM': this.utilCustomCSSPropertyService.setValue(prop, '16px', this.elementRef?.nativeElement); break;
      case 'LARGE': this.utilCustomCSSPropertyService.setValue(prop, '18px', this.elementRef?.nativeElement); break;
      case 'EXTRA_LARGE': this.utilCustomCSSPropertyService.setValue(prop, '20px', this.elementRef?.nativeElement); break;
      default: console.warn('Was not able to set size of the component with', value); break;
    }
  }

  @Output()
  readonly info = new EventEmitter<UIEvent>();

  constructor(private elementRef: ElementRef, private readonly kurzUnitService: KurzUnitService, private readonly utilCustomCSSPropertyService: UtilCustomCSSPropertyService) { }

  ngOnInit(): void {
    const host = this.elementRef?.nativeElement as HTMLElement;
    const priceContainer = host.children[1];

    if (priceContainer) {
      this.resizeObserver.observe(priceContainer);
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private calculateSeparationLineLength() {
    const host = this.elementRef?.nativeElement as HTMLElement;
    const priceContainer = host.children[1];

    const width = priceContainer?.getBoundingClientRect?.()?.width || 0;

    // console.log('separation length =', length);
    this.utilCustomCSSPropertyService.setValue('--kurz-price-container-width', width + 'px', this.elementRef?.nativeElement);
  }

  infoActivated(e: UIEvent) {
    this.info.emit(e);
  }

}
